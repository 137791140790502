import React, {Component} from 'react';
import {Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import formatAmount from '../utils/formatAmount';

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleError: '',
      descError: '',
      termError:'',
    };
  }

  componentDidUpdate(prevProps) {
    const { title, description } = this.props;

    if (title !== prevProps.title) {
      this.setState({
        titleError:
          title.length < 3
            ? 'Tytuł płatności powinien składać się z co najmniej 3 znaków'
            : '',
      });
    }
    if (description !== prevProps.description) {
      this.setState({
        descError:
          description.length < 3 && description.length > 0
            ? 'Opis płatności powinien składać się z co najmniej 3 znaków'
            : '',
      });
    }
  }

  isBtnDisabled() {
    const { titleError, descError, termError } = this.state;
    const { title, loading } = this.props;

    return loading || !title || !!titleError || !!descError || !!termError;
  }

  render() {
    const {
      amount,
      currency,
      term,
      title,
      description,
      handleChange,
      handleSubmit,
      formErrors,
      decrementStep,
      renewal,
      isSubmitted
    } = this.props;
    return (
      <>
        <form onSubmit={handleSubmit}>
          <Box p={1} textAlign='center'>
            <Typography variant='body1' paragraph>
              <b>Kwota płatności:</b> {formatAmount(amount, currency)}
            </Typography>
          </Box>
          {/* <pre>{JSON.stringify(this.state.termError, null, 2)}</pre> */}
          <Grid container justifyContent='center'>

            <Grid item xs={10} md={4}>
              <Box pt={2} textAlign='center'>
                <TextField
                  variant='outlined'
                  label='Tytuł płatności'
                  name='title'
                  type='text'
                  placeholder='(wyświetlany dla Płatnika)'
                  value={title}
                  onChange={handleChange}
                  fullWidth
                  required
                  error={!!(formErrors.title || this.state.titleError)}
                  helperText={formErrors.title || this.state.titleError}
                  inputProps={{ maxLength: 128 }}
                />
              </Box>
              <Box pt={2} textAlign='center'>
                <DatePicker
                  autoOk
                  variant='inline'
                  inputVariant='outlined'
                  label='Termin płatności'
                  value={term}
                  name='term'
                  format='dd/MM/yyyy'
                  minDate='1970-01-01 00:00:01'
                  maxDate='2038-01-19 03:14:07'
                  onError={newError => this.setState({ termError: newError })}
                  onChange={value => {
                    if (value instanceof Date && !isNaN(value)) {
                      handleChange({ target: { name: 'term', value: value } });
                    }
                   else {
                    handleChange({ target: { name: 'term', value: '' } });
                  }
                  }}
                  required
                  renderInput={props => (
                    <TextField
                      {...props}
                      fullWidth
                      required
                      helperText={this.state.termError && 'Niepoprawny format daty'}
                    />
                  )}
                />
              </Box>
              <Box pt={2} textAlign='center'>
                <TextField
                  variant='outlined'
                  label='Opis płatności'
                  name='description'
                  type='text'
                  placeholder='(widoczny tylko dla Ciebie)'
                  value={description}
                  onChange={handleChange}
                  fullWidth
                  error={!!(formErrors.description || this.state.descError)}
                  helperText={formErrors.description || this.state.descError}
                  inputProps={{ maxLength: 128 }}
                />
              </Box>
              <Box textAlign='start'>
                <FormControlLabel control={
                  <Checkbox
                    checked={renewal.create}
                    onChange={e => {
                      handleChange({
                        target: {
                          name: 'renewal',
                          value: {...renewal, create: e.target.checked, dialogOpen: e.target.checked}
                        }
                      })
                    }}
                  />}
                  label="Płatność cykliczna"
                />
              </Box>
            </Grid>
          </Grid>
          <Box p={4}>
            <Grid container spacing={2} justifyContent={'center'}>
              <Grid item>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={decrementStep}
                >
                  Wstecz
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  disableElevation
                  disabled={this.isBtnDisabled() || isSubmitted}
                  type='submit'
                >
                  Dalej
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </>
    );
  }
}

export default OrderDetails;

import * as React from 'react';
import Layout from '../templates/layout';
import Payment from '../components/Payment';

const PaymentPage = () => (
  <Layout>
    <Payment />
  </Layout>
);

export const Head = () => <title>Nowa płatność | Zapłatomat</title>;

export default PaymentPage;

import React, {useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import * as dateFns from "date-fns";
import {bindActionCreators} from "redux";
import * as actions from "../../actions";
import {connect} from "react-redux";
import {formatDuration} from "date-fns";
import {pl} from "date-fns/locale";

const INITIAL_DATA = {
  intervalAmount: 1,
  intervalType: 'month',
  endType: 'none',
  ordersCountLimit: 10,
  endsAt: dateFns.addMonths(new Date(), 12),
  startsAt: new Date(),
};

const mapStateToProps = ({createOrderRenewal}) => ({createOrderRenewal})

const formatData = (data) => ({
    ...data,
    startsAt: dateFns.format(data.startsAt, 'yyyy-MM-dd HH:ii:ss'),
    endsAt: data.endType === 'date' ? dateFns.format(data.endsAt, 'yyyy-MM-dd') : null,
    ordersCountLimit: data.endType === 'count' ? data.ordersCountLimit : null,
  }
)

const getFormattedIntervalType = (data, type, fallback) => {
  const ret = formatDuration({[type]: 1}, {locale: pl})
    .split(data.intervalAmount)[1];
  return ret || fallback;
}

const MonthIntervalInfo = ({startDay}) => {
  let dayInfo = startDay;
  if (startDay > 28) dayInfo += ' lub ostatniego';
  return (
    <Typography variant='body2' py={1}>
      {`Płatność będzie tworzona ${dayInfo} dnia miesiąca.`}
    </Typography>
  );
}

const CreateRenewalDialog = ({
                               handleSubmit,
                               renewalDialogOpen,
                               closeRenewalDialog
                             }) => {
  const [data, setData] = useState(INITIAL_DATA);
  const [startsAtError, setStartsAtError]=useState('');
  const [endsAtError, setEndsAtError]=useState('');

  const isValid = () => {
    if (data.endType === 'date') {
      if (dateFns.isBefore(data.startsAt, dateFns.startOfDay(new Date()))) {
        return false
      }
      if (dateFns.compareAsc(data.endsAt, data.startsAt) < 1) {
        return false;
      }
    }
    if (data.endType === 'count' && isNaN(data.ordersCountLimit)) {
      return false
    }
    if (startsAtError || endsAtError) {
      return false
    }

    return true;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isValid()) return;

    const formattedData = formatData(data);
    handleSubmit({...formattedData})
  };

  return (
    <Dialog open={renewalDialogOpen} onClose={closeRenewalDialog} maxWidth='xs'>
      <form onSubmit={onSubmit}>
        <DialogTitle>Płatność cykliczna</DialogTitle>
              {/* <pre>{JSON.stringify(data.ordersCountLimit, null, 2)}</pre>
              <pre>{JSON.stringify(data.endType, null, 2)}</pre> */}
        <DialogContent>
          <FormControl>
            <FormLabel my={10}>Powtarzaj co:</FormLabel>
            <Box sx={{ mb: 4 }}>
              <TextField
                value={data.intervalAmount}
                onChange={e =>
                  setData({ ...data, intervalAmount: parseInt(e.target.value) })
                }
                type='number'
                variant='outlined'
                required
                InputProps={{
                  inputProps: { min: 1, max: 60 },
                }}
              />
              <Select
                value={data.intervalType}
                sx={{ mx: 2, minWidth: '15ch' }}
                onChange={e =>
                  setData({ ...data, intervalType: e.target.value })
                }
              >
                <MenuItem value='day'>
                  {getFormattedIntervalType(data, 'days', 'dni')}
                </MenuItem>
                <MenuItem value='week'>
                  {getFormattedIntervalType(data, 'weeks', 'tygodnie')}
                </MenuItem>
                <MenuItem value='month'>
                  {getFormattedIntervalType(data, 'months', 'miesiące')}
                </MenuItem>
              </Select>
              {data.intervalType === 'month' &&
                data.startsAt instanceof Date && (
                  <MonthIntervalInfo startDay={data.startsAt.getDate()} />
                )}
            </Box>
          </FormControl>
          <DatePicker
            autoOk
            variant='inline'
            inputVariant='outlined'
            label='Data rozpoczęcia'
            value={data.startsAt}
            name='startsAt'
            format='dd/MM/yyyy'
            minDate={new Date()}
            maxDate='2038-01-19 03:14:07'
            onError={newError => setStartsAtError(newError)}
            onChange={startsAt => {
              if (startsAt instanceof Date && !isNaN(startsAt)) {
                setData({ ...data, startsAt: startsAt });
              } else {
                setData({ ...data, startsAt: '' });
              }
            }}
            required
            renderInput={props => (
              <TextField
                {...props}
                helperText={
                  startsAtError &&
                  'Nieprawidłowy format daty lub data z przeszłości'
                }
                fullWidth
              />
            )}
          />
          <Box sx={{ mt: 4 }}>
            <FormControl>
              <FormLabel>Koniec:</FormLabel>
              <Box sx={{ display: 'flex' }}>
                <Select
                  value={data.endType}
                  sx={{ mr: 2 }}
                  onChange={e => setData({ ...data, endType: e.target.value })}
                >
                  <MenuItem value='none'>Bezterminowo</MenuItem>
                  <MenuItem value='date'>Data zakończenia</MenuItem>
                  <MenuItem value='count'>Po liczbie transakcji</MenuItem>
                </Select>
                {data.endType === 'date' && (
                  <DatePicker
                    autoOk
                    variant='inline'
                    inputVariant='outlined'
                    label='Data zakończenia'
                    value={data.endsAt}
                    minDate={dateFns.startOfDay(new Date())}
                    maxDate='2038-01-19 03:14:07'
                    onError={newError => setEndsAtError(newError)}
                    name='endsAt'
                    format='dd/MM/yyyy'
                    onChange={endsAt => {
                      if (endsAt instanceof Date && !isNaN(endsAt)) {
                        setData({ ...data, endsAt: endsAt });
                      } else {
                        setData({ ...data, endsAt: '' });
                      }
                    }}
                    required
                    renderInput={props => (
                      <TextField
                        {...props}
                        helperText={
                          endsAtError &&
                          'Nieprawidłowy format daty'
                        }
                        fullWidth
                      />
                    )}
                  />
                )}
                {data.endType === 'count' && (
                  <TextField
                    value={data.ordersCountLimit}
                    onChange={e => {
                        setData({
                          ...data,
                          ordersCountLimit: parseInt(e.target.value),
                        });
                      }
                    }
                    type='number'
                    label='Liczba transakcji'
                    variant='outlined'
                    sx={{ mr: 1, width: '20ch' }}
                    InputProps={{
                      inputProps: { min: 2, max: 100 },
                    }}
                  />
                )}
              </Box>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={closeRenewalDialog}>
            Anuluj
          </Button>
          <Button
            variant='contained'
            type='submit'
            disabled={!isValid()}
            disableElevation
          >
            Utwórz
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators(actions, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(CreateRenewalDialog);

import React from 'react';
import { connect } from 'react-redux';
import { Box, Container } from '@mui/material';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby'
import * as dateFns from "date-fns";
import * as actions from '../actions';
import OrderAmount from './payment/OrderAmount';
import OrderDetails from './payment/OrderDetails';
import FormComponent from './containers/FormComponent';
import PaymentPartner from './PaymentPartner';
import CreateRenewalDialog from "./orderRenewals/CreateRenewalDialog";

const mapStateToProps = state => ({
  orderLimits: state.checkOrderLimits,
  order: state.createOrder,
});

class Payment extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      loading: false,
      step: 0,
      amount: '',
      currency: 'PLN',
      title: '',
      description: '',
      term: dateFns.addDays(new Date(), 7),
      isPartnerChecked: false,
      isSubmitted:false,
      renewal: {
        dialogOpen: false,
        create: false,
        intervalAmount: 30,
        intervalType: 'day',
        endType: 'date',
        ordersCountLimit: 10,
        endsAt: dateFns.addDays(new Date(), 7),
        startsAt: new Date(),
      }
    };
  }

  componentDidMount() {
    this.props.actions.getPartner();
  }

  componentDidUpdate(prevProps) {
    const { orderLimits, order } = this.props;
    if (orderLimits !== prevProps.orderLimits) {
      this.validateResponse(orderLimits, () => {
        if (orderLimits.data) {
          this.setState({ loading: false });
          this.incrementStep();
        }
      });
    }

    if (order !== prevProps.order) {
      this.validateResponse(order, () => {
        if (order.data) {
          navigate(`/orders/${order.data.code}`);
        }
      });
    }
  }

  handleSubmitAmount = e => {
    this.handleSubmitForm(() => {
      const data = {
        amount: this.state.amount.replace(',', '.'),
        currency: this.state.currency,
      };
      this.props.actions.checkOrderLimits(data);
      this.setState({ loading: true });
    }, e);
  };

  handleSubmitOrder = e => {
    this.handleSubmitForm(() => {
      const {amount, currency, term, title, description, renewal} = this.state;
      const data = {
        amount: amount.replace(',', '.'),
        term: dateFns.format(term, 'yyyy-MM-dd'),
        currency,
        title,
        description,
      };

      if(renewal.create) {
        data.renewal = {
          intervalAmount: renewal.intervalAmount,
          intervalType: renewal.intervalType,
          endType: renewal.endType,
          ordersCountLimit: renewal.ordersCountLimit,
          endsAt: renewal.endsAt,
          startsAt: renewal.startsAt,
        }
      }
      this.setState({ isSubmitted: true });
      this.props.actions.createOrder(data);
    }, e);
  };

  handlePartnerCheck = () => {
    this.setState({ isPartnerChecked: true });
  };

  incrementStep = () => {
    const { step } = this.state;
    if (step < 1) {
      this.setState({ step: step + 1 });
    }
  };

  decrementStep = () => {
    const { step } = this.state;
    if (step > 0) {
      this.setState({ step: step - 1 });
    }
  };

  getStepContent = () => {
    const {
      amount,
      currency,
      step,
      formErrors,
      loading,
      title,
      term,
      description,
      renewal,
      isSubmitted,
    } = this.state;
    switch (step) {
      case 0:
        return (
          <OrderAmount
            amount={amount}
            currency={currency}
            handleChange={this.handleValueChange.bind(this)}
            handleSubmit={this.handleSubmitAmount}
            incrementStep={this.incrementStep}
            formErrors={formErrors}
            loading={loading}
          />
        );
      default:
        return (
          <OrderDetails
            amount={amount}
            currency={currency}
            title={title}
            term={term}
            description={description}
            handleChange={this.handleValueChange.bind(this)}
            handleSubmit={this.handleSubmitOrder}
            decrementStep={this.decrementStep}
            formErrors={formErrors}
            loading={loading}
            renewal={renewal}
            isSubmitted={isSubmitted}
          />
        );
    }
  };

  content = () => {
    if (!this.state.isPartnerChecked) {
      return <PaymentPartner onCheck={this.handlePartnerCheck} />;
    } else {
      return this.getStepContent();
    }
  };

  render() {
    return (<>
      <Container>
        <Box p={4} textAlign='center'>
          {this.content()}
        </Box>
      </Container>
      <CreateRenewalDialog
        renewalDialogOpen={this.state.renewal.dialogOpen}
        closeRenewalDialog={() => this.setState({ renewal: {...this.state.renewal, create: false, dialogOpen: false}})}
        handleSubmit={(data) => this.setState({ renewal: {...data, create: true, dialogOpen: false }})}
       />
    </>);
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Typography, Button, CardContent, CircularProgress,
} from '@mui/material';
import { navigate } from 'gatsby';
import { bindActionCreators } from 'redux';
import * as actions from '../actions';

const partnerTypeMapper = {
  partner_create: {
    message: 'Rozpocznij proces weryfikacji konta.',
    actionDesc: 'Przejdź do ustawień weryfikacji tożsamości',
    path: '/user/identity',
  },
  complete_verification: {
    message: 'Przejdź do ustawień weryfikacji tożsamości.',
    actionDesc: 'Przejdź do ustawień weryfikacji tożsamości',
    path: '/user/identity'
  },
  set_default_account: {
    message: 'Wskaż konto, na które chcesz otrzymać pozyskane środki.',
    actionDesc: 'Przejdź do ustawień konta bankowego',
    path: '/user/accounts',
  }
}

export function mapStateToProps(state) {
  return {
    partner: state.getPartner,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}


class PaymentPartner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      show: false,
      type: null,
    };
  }

  componentDidMount() {
    this.props.actions.getPartner();
  }

  componentDidUpdate(prevProps) {
    if (this.props.partner !== prevProps.partner) {
      this.checkConfigurationReady(this.props.partner);
    }
  }

  checkConfigurationReady(partner) {
    let type;
    if (!partner.data) {
      type = 'partner_create';
    } else {
      if(partner.data.kycStatus !== 'accepted'){
        type = 'complete_verification';
      } else if(!partner.data.hasDefaultAccount){
        type = 'set_default_account';
      }
    }

    if(type) {
      this.setState({
        show: true,
        type: type,
        loading: false,
      })
    } else {
      this.props.onCheck(true);
    }
  }

  render() {
    let data = partnerTypeMapper[this.state.type];
    if(this.state.loading || !data)
      return (
        <Container>
          <CircularProgress/>
        </Container>
      )
    const {message, path, actionDesc} = data;
    return (
      <Fragment>
        <CardContent>
          <Typography
            color='textSecondary'
            gutterBottom
          >
            {message}
          </Typography>
        </CardContent>
        <Button
          color='primary'
          variant='outlined'
          onClick={() => navigate(path)}
        >
          {actionDesc}
        </Button>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPartner);

import React, { Component } from 'react';
import {
  Box,
  Button,
  InputAdornment,
  Typography,
  TextField,
  Container,
} from '@mui/material';

class OrderAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      error: false,
      helperText: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { amount } = this.props;
    if (amount !== prevProps.amount) {
      // eslint-disable-next-line no-useless-escape
      const regExp = /^\d+([\.\,]\d{1,2})?$/;
      if (!amount) {
        this.setState({
          helperText: 'Podaj kwotę',
        });
      } else if (!regExp.test(amount)) {
        this.setState({
          error: true,
          helperText: 'Nieprawidłowy format kwoty',
        });
      } else if (amount > 200000) {
        this.setState({
          error: true,
          helperText: 'Kwota nie może być większa niż 200000 PLN',
        });
      } else if (amount < 5) {
        this.setState({
          error: true,
          helperText: 'Kwota nie może być mniejsza niż 5 PLN',
        });
      } else {
        this.setState({
          error: false,
          helperText: '',
        });
      }
    }
  }

  isSubmitDisabled = () => {
    return this.state.error;
  };

  render() {
    const {
      amount,
      currency,
      handleSubmit,
      handleChange,
      formErrors,
      loading
    } = this.props;
    return (
      <>
        <Container component='main' maxWidth='xs'>
          <form onSubmit={handleSubmit}>
            <Typography>Podaj pełną kwotę do zapłaty</Typography>
            <Box p={2}>
              <TextField
                id='amount'
                label='Kwota'
                variant='outlined'
                pattern='[0-9.,]+'
                name='amount'
                value={amount}
                placeholder='Kwota'
                maxLength='10'
                onChange={handleChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>{currency}</InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 10 }}
                error={!!(this.state.helperText || formErrors.amount)}
                helperText={this.state.helperText || formErrors.amount}
              />
            </Box>
            <Box p={2}>
              <Button
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={false}
                variant='contained'
                color='primary'
                disabled={this.isSubmitDisabled() || loading}
                type='submit'
                fullWidth
                disableElevation
              >
                Dalej
              </Button>
            </Box>
          </form>
        </Container>
      </>
    );
  }
}

export default OrderAmount;
